import { SponserDubai2024 } from "config";
import React from "react";
import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  return (
    <>
      <div style={{ background: "#f5f5f5" }} className="section" data-parallax={true}>
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-300 text-center text-dark mb-5">2024 Sponsor & Partners</h1>
            </Col>
          </Row>
        <Row>
            {SponserDubai2024.map((s) => (
            <Col lg={s.size} xs={6} key={s.id} className={"ml-auto mr-auto pb-3"}>
                <a href={s.url} target="_BLANK" rel="noreferrer">
                <Card className="rounded-0">
                    <Container fluid>
                    {/* <h5
                        className="text-400 text-dark text-center mb-3"
                        style={{
                        marginTop: '25px',
                        minHeight: '3.5rem',
                        fontWeight: '600',
                        }}
                    >
                        {s.type}
                    </h5> */}
                    <img src={s.image} alt={s.name} width="100%" className="rounded-0 pb-3" />
                    </Container>
                </Card>
                </a>
            </Col>
            ))}
        </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
