import React from "react";
import Navbar from "componentsDubai/Navbar.js";
import ProfilePageHeader from "componentsDubai/Headers/ProfilePageHeader.js";

import Footer from "componentsDubai/Footer.js";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={`THANK YOU `} bgColor="#000" />
      <div className="section profile-content" style={{ padding: 0, background: "#000" }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center text-dark p-5 m-5">
              <h2 className="text-white text-400">
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest to join the Business Innovation & Technology Summit!
                    <br />
                    <br />{" "}
                    <p className="text-400 mt-3" style={{ fontSize: 20 }}>
                      As the event is for pre-qualified attendees only. A member of our team will be
                      in touch with the confirmation and for further details about the event.
                    </p>
                  </>
                )}
                {id === "AGENDA" && (
                  <>
                    Thank you for your interest in the Business Innovation & Technology Summit. A
                    member of our team will be in touch with you for further details about the
                    event.
                  </>
                )}

                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in the Business Innovation & Technology Summit. A
                    member of our team will be in touch with you for further details about the
                    event.
                  </>
                )}
              </h2>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Sponsor sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default ThankYou;
