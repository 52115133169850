import React from "react";
import { Route } from "react-router-dom";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Home from "./viewsDubai/home";
import RegisterPage from "./viewsDubai/Register.js";
import StartupLounge from "./viewsRiyadh/StartupLounge.js";
import ThankYouRegister from "./viewsDubai/ThankYouRegister.js";
import PrivacyPolicy from "./viewsRiyadh/PrivacyPolicy.js";
import Termsconditons from "./viewsRiyadh/Termsconditions.js";
import Speakers from "./viewsRiyadh/Speakers.js";
import ContactUs from "./viewsDubai/ContactUs.js";
import ThankYouStartup from "./viewsRiyadh/ThankYouStartup.js";
import Agenda from "viewsRiyadh/Agenda.js";

const Routes = () => (
  <>
    {/* // <Switch> */}
    <Route exact path="/dubai" render={(props) => <Home {...props} />} />
    <Route
      exact
      path="/dubai/register"
      render={(props) => (
        <RegisterPage
          {...props}
          type="REGISTER"
          title="Register Now"
          subject=""
        />
      )}
    />

    <Route
      path="/dubai/contact"
      render={(props) => <ContactUs {...props} />}
    />
    <Route path="/dubai/agenda" render={(props) => <Agenda {...props} />} />
    <Route
      path="/dubai/request-brochure"
      render={(props) => (
        <RegisterPage
          {...props}
          type="AGENDA"
          title="Download Brochure"
          subject=""
        />
      )}
    />

    <Route
      path="/dubai/sponsor"
      exact
      render={(props) => (
        <RegisterPage
          {...props}
          type="SPONSOR"
          title="Sponsor - BIT Summit"
          subject=""
        />
      )}
    />
    <Route
      path="/dubai/privacy-policy"
      exact
      render={(props) => <PrivacyPolicy {...props} />}
    />
    <Route
      path="/dubai/terms"
      exact
      render={(props) => <Termsconditons {...props} />}
    />
    <Route
      path="/dubai/speakers"
      exact
      render={(props) => <Speakers {...props} />}
    />
    <Route
      path="/dubai/startup-lounge"
      exact
      render={(props) => (
        <StartupLounge
          {...props}
          title="Fintech Innovations Lounge Program Application Form"
        />
      )}
    />
    <Route
      path="/dubai/thank-you-startup"
      exact
      render={(props) => <ThankYouStartup {...props} />}
    />
    <Route
      path="/dubai/thank-you/:id"
      exact
      render={(props) => <ThankYouRegister {...props} />}
    />
  </>
  // </Switch>
);

export default Routes;